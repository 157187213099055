// import http from '../utils/requerts/request'
import http from "@/utlis/requerts/request"

//get有值
export function LoginPost(data: any) {
  return http.request({
    url: '/Login',
    method: 'post',
    data:data
  })
}
export function Register(data: any) {
    return http.request({
      url: '/Register',
      method: 'post',
      data:data
    })
  }

  //获取手机验证码
  export function postPCode(data: any) {
    return http.request({
      url: '/RegisterSendPhoneVerificationCode',
      method: 'post',
      data:data
    })
  }
  export function postECode(data: any) {
    return http.request({
      url: '/RegisterSendEmailVerificationCode',
      method: 'post',
      data:data
    })
  }

//激活
  export function TempSetUserAuthority(params: any) {
    return http.request({
      url: '/TempSetUserAuthority/'+params.UserName,
      method: 'get',
      // params
    })
  }
 

//检查用户名是否可以注册
  export function CheckUserName(data: any) {
    return http.request({
      url: '/CheckUserName',
      method: 'post',
      data:data
    })
  }


  //检查邮箱是否可以注册
  export function CheckEmail(data: any) {
    return http.request({
      url: '/CheckEmail',
      method: 'post',
      data:data
    })
  }

   //检查手机号是否可以注册
   export function CheckPhoneNumber(data: any) {
    return http.request({
      url: '/CheckPhoneNumber',
      method: 'post',
      data:data
    })
  }

  
// 测试token接口
//   export function TestAuth() {
//     return http.request({
//       url: '/TestAuth',
//       method: 'post',
//     //   data:data
//     })
//   }

  export function TestAuth(params: any) {
    return http.request({
      url: '/TestAuth',
      method: 'get',
      params
    })
  }
  


  
// //get无值
// export function (params: any) {
//   return http.request({
//     url: '/test',
//     method: 'get',
//     params
//   })
// }
