<template>
  <div class="content">
    <div class="from_cont">
      <div class="logo">
        <div class="img">
          <img src="../assets/logo.svg"/>
        </div>
        <div class="name">GalaxyQuantitativ</div>
        <!-- <img src="../assets/from_logo.png" /> -->
      </div>
      <div class="tip_img">
        登录
        <!-- <img src="../assets/login.png" /> -->
      </div>
    </div>

    <div class="data_cont">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        status-icon
        :rules="rules"
        class="demo-ruleForm"
      >
        <el-form-item prop="UserName">
          <el-input
            v-model="ruleForm.UserName"
            placeholder="用户名/邮箱/手机号码"
          >
            <template #prefix>
              <div class="data__icon">
                <img src="../assets/user.png" />
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="Password">
          <el-input type="password" v-model="ruleForm.Password" placeholder="请输入登录密码">
            <template #prefix>
              <div class="data__icon">
                <img src="../assets/password.png" />
              </div>
            </template>
          </el-input>
        </el-form-item>

        <!-- <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">
            Submit
          </el-button>
          <el-button @click="resetForm(ruleFormRef)">Reset</el-button>
        </el-form-item> -->
        <div class="sub_bnt" @click="submit">
          <div class="name">立即登录</div>
          <div class="img">
            <img src="../assets/right.png"/>
          </div>
          <!-- <img src="../assets/button.png" /> -->
        </div>
      </el-form>

      <div class="tip_cont" @click="router.push('/sign')">
        创建一个账号？
        <!-- <img src="../assets/tip.png" /> -->
      </div>
    </div>

    <div class="logo_btm">
     
      <img src="../assets/btmimg.png" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { reactive, ref, onMounted } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import { ElMessage } from "element-plus";

import router from "@/router";
import { LoginPost } from "@/api/api";
const ruleForm = reactive({
  UserName: "",
  Password: "",
});
onMounted(() => {  
  const resetAutofillStyle = (input: HTMLInputElement) => {  
      // 设置一个短暂的延迟来等待浏览器应用其自动填充样式  
      setTimeout(() => {  
        input.style.backgroundColor = '';  
        input.style.color = '';  
        // 可以添加更多需要重置的样式  
      }, 50); // 延迟时间可能需要根据你的具体需求进行调整  
    };  
    });  

const ruleFormRef = ref<any | null>(null);

function submit() {
  ruleFormRef.value.validate((valid: any) => {
    if (valid) {
      let data = {
        UserName: ruleForm.UserName,
        Password: ruleForm.Password,
      };
      LoginPost(data).then((res: any) => {
        if (res.code == 0) {
          ElMessage({
            message: "登录成功",
            type: "success",
          });
          localStorage.setItem("userinfo",JSON.stringify(res.data))

          localStorage.setItem("appToken", res.data.token);
          router.replace("/home2");
        } else {
          ElMessage({
            message: res.message,
            type: "error",
          });
        }
      });
    } else {
      console.log("错误提交!");
      return false;
    }
  });
}



const validateUserName = (rule: any, value: any, callback: any) => {
  if (value === "") {
    callback(new Error("请输入正确信息"));
  } else {
    if (ruleForm.UserName !== "") {
      if (!ruleFormRef.value) return;
    }
    callback();
  }
};

const rules = reactive<FormRules<typeof ruleForm>>({
  UserName: [{ validator: validateUserName, trigger: "blur" }],
  Password: [{ validator: validateUserName, trigger: "blur" }],
});


// const resetForm = (formEl: FormInstance | undefined) => {
//   if (!formEl) return;
//   formEl.resetFields();
// };
</script>
<style lang="scss" scoped>
.content {
  background-color: #000;
  padding: 26px 0;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/bg.png);
  height: 100vh;
  box-sizing: border-box;
  //   max-width: 1200px; /* 或其他固定宽度 */
  background-position: center center; /* 将图片居中显示 */
  background-repeat: no-repeat; /* 防止图片重复 */
  background-size: 100% 100%; /* 覆盖整个元素，必要时裁剪图片 */
  position: relative;
  .logo_btm {
    
    width: 386px;
    position: absolute;
    bottom: 0;
    right: 0;
    img {
      width: 100%;
    }
  }
}
.from_cont {
  max-width: 1200px;
  // margin: auto;
  // background-color: #fff;
  .logo {
    display: flex;
    align-content: center;
    .img{
      width: 88px;
      height: 88px;
    }
    img{
      width: 100%;
    }
    .name{
      display: flex;
      align-items: center;
      font-size: 38px;
      color: #ffffff;
    }

    // width: 318px;
    // // margin: auto;
    // margin-bottom: 38px;
    // img {
    //   width: 100%;
    // }
  }
  .tip_img {
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    margin: auto;
    // width: 78px;
    text-align: center;
    margin-bottom: 38px;
    img {
      width: 100%;
    }
  }
}

.data__icon {
  width: 20px;
  display: flex;
  align-items: center;
  margin-right: 20px !important;
  img {
    width: 100%;
  }
}
.data_cont {
  width: 720px;
}
// ::v-deep .el-input__inner::-placeholder {
//   color: red !important; /* 设置placeholder的字体颜色 */
//   font-size: 12px; /* 尝试设置placeholder的字体大小，但请注意，这可能不会在所有浏览器中生效 */
// }
::v-deep .el-input__inner {
  padding: 10px 0;
}
::v-deep .el-input--prefix .el-input__inner::placeholder {
  color: #a1a1aa !important;
  font-size: 18px;
}
// el-input__inner
::v-deep .el-input__inner {
  padding: 32px 0 !important;
  font-size: 18px;
  color: #fff;
}
::v-deep .el-input__wrapper {
  background-color: #18181b !important;
  border-radius: 12px !important;
  padding-left: 24px;
}
::v-deep .el-input__wrapper {
  box-shadow: none !important;
}
.el-form-item {
  margin-bottom: 28px !important;
}
.sub_bnt {
  cursor: pointer;
  width: 198px;
  margin: auto;
  margin-top: 32px;
  font-size: 24px;
  background-color: rgba(157, 255, 123, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  border-radius: 16px;
  .img{
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }
  img {
    width: 100%;
  }
}
.tip_cont {
  text-align: center;
  color: rgb(113,113,122);
  font-size: 20px;
  // width: 124px;
  cursor: pointer;
  margin: auto;
  margin-top: 28px;

  img {
    width: 100%;
  }
}
v-deep input:-internal-autofill,  
input:-internal-autofill-selected {  
    /* 尝试覆盖背景色和文本色 */  
    background-color: white !important;  
    color: black !important;  
  
    /* 尝试去除默认的填充阴影（如果有的话） */  
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;  
  
    /* 尝试去除动画（如果有的话） */  
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;  
}
</style>