<template>
  
  <router-view />
</template>

<script lang="ts" setup>
import { watchEffect, onMounted } from 'vue';  
import { useRoute } from 'vue-router';  
  
const route = useRoute();  
watchEffect(() => {  
  // 检查当前路由的 meta 字段中是否有 title  
  if (route.meta && (route.meta.title as string)) {  
    document.title = route.meta.title as string;  
  }  
}); 

</script>

<style lang="scss">
#app {

}
body{
  margin: 0;
  min-width: 1200px;
  // width: 100%;
}
</style>
