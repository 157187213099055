import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/login.vue";
import Layout from "@/components/layout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {  
      title: '登录 '  
    }  
  },
  {
    path:"/",
    redirect:"/home",
    component:Layout,
    children:[
      {
        path:"home",
        component:()=>import('../views/home2.vue'),
        meta: {  
          title: '银河量化：为您的交易赋能'  
        }  
      },
    ]

  },
  {
    path: "/sign",
    name: "sign",
    component: () =>
      import("../views/sign.vue"),
    meta: {  
      title: '注册 '  
    }  
  },
];

function isRouteDefined(routes:any, path:any) {  
  for (let route of routes) {  
    if (route.path === path) {   
      return true;  
    }  
    
  }  
  return false;  
}  
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {  

  const allowedPaths = ['/login', '/sign', '/home']; // 注意：'/'不会直接匹配，因为它重定向到'/home2'  
  if (!allowedPaths.includes(to.path) && !to.matched.some(record => record.path === '/')) {  
    // 如果路径不在允许的列表中，并且不是根路径的重定向目标  
    // 注意：这里的'/'检查可能不是必要的，取决于你的具体需求  
    next('/'); // 重定向到根路径，它会进一步重定向到'/home2'  
  } else {  
    // 如果路径是允许的，或者Vue Router已经找到了匹配的路由  
    next(); // 继续路由导航  
  }  


  
  

});

export default router;
